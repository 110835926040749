import React from 'react';
import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>
            <span className="icon fa-youtube">&nbsp;</span>
            Youtube
          </h2>
          <p>SESSIONS WEST CHANNEL</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
        <h3 className="major">Ray Band 1985 on Youtube</h3>
        <iframe width="100%" height="700px" src="https://www.youtube.com/embed/dK4jVth01x8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
